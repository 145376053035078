import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const routes = [
  {
    path: '/',
    component: () => import('@/home/home.vue'),
    children: [
      {
        path: '/',
        name: 'homePage',
        meta: {
          title: "首页"
        },
        component: () => import('@/views/homePage.vue')
      },
      {
        path: '/productList',
        name: 'productList',
        meta: {
          title: "产品列表",
        },
        component: () => import('@/views/productList.vue')
      },
      {
        path: '/productIntroduction',
        name: 'productIntroduction',
        meta: {
          title: "产品介绍",
        },
        component: () => import('@/views/productIntroduction.vue')
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        meta: {
          title: "关于我们",
        },
        component: () => import('@/views/aboutUs.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  // scrollBehavior: () => {
  //   history.pushState(null, null, document.URL)
  // }

})

export default router