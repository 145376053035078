<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 1200px;
  overflow: auto;
  font-family: "微软雅黑" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  height: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f8f8f8;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #e0e0e0;
}
.el-dialog__body {
  max-height: 400px !important;
  overflow: auto;
}
.el-dialog__header {
  border-bottom: 1px solid #ebeef5;
}
.el-dialog__footer {
  border-top: 1px solid #ebeef5;
}

@media (max-width: 750px) {
  #app {
    width: 100%;
    min-width: auto;
  }
  .el-dialog__body {
    max-height: none !important;
    overflow: auto;
  }
}
</style>
