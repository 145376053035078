import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import router from './router'
import "@/api/request.js";
import './element-variables.scss'
import axios from "axios";
Vue.prototype.$axios = axios;
Vue.config.productionTip = false
Vue.use(ElementUI);
// 设置语言
locale.use(lang)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
